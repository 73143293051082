import { useEffect, useState } from "react";
import { Col, Row, Space, Typography, Button, Grid, Popover, Modal } from "antd";
import {
    PH_logo_light_tm
} from "@ph/common/assets";
import style from "./PHCartHeader.module.scss";
import PhButton from "../PhButton/PhButton";
import { useCart } from "@ph/common/context/CartContext";
import { PER_MONTH_LABEL } from "@ph/common/global/Constants";
import { REPORT_PLAN_DETAILS } from "@ph/common/constants/Report/ReportConstants";
import MD03UpgradeModal from "../../Report/ReportContainer.js/MD03UpgradeModal";
import { REPORT_PLAN_MODAL_DETAILS } from "@ph/common/constants/Report/ReportConstants";
import { REPORT_PLANS } from "@ph/common/constants/Report/ReportConstants";
import { formatFloat } from "@ph/common/global/Constants";
import { admin_logout, search_icon_light } from "@ph/common/assets";
import OtpInput from "react18-input-otp";
import { useNavigate } from "react-router-dom";


const { Text, Link, Title } = Typography;
const { useBreakpoint } = Grid;


const PHCartHeader = ({ isAdmin, codeHeader, handleInputChange, disableContinueHeader, reportSearchClickHandler }) => {
    const [showHeader, setShowHeader] = useState(false);
    const [openCart, setOpenCart] = useState(false)
    const [total, setTotal] = useState(0)
    const { products, includedProducts, md03Product, reportPlan, planModalType, planModal, updatePlanModal, downgradeMD03Plan } = useCart();
    const screens = useBreakpoint();
    const isMobile = screens.xs

    useEffect(() => {
        const handleScroll = () => {
            window.scrollY > 100 ? setShowHeader(true) : setShowHeader(false);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const getVariantUrl = (productArr) => {
        const variant_id_key = REPORT_PLAN_DETAILS[reportPlan]?.product_variant_key
        const selling_plan_key = REPORT_PLAN_DETAILS[reportPlan]?.product_selling_plan_key

        let url = ''

        productArr.forEach(variant => {
            if (variant?.product_id === md03Product?.product_id) {
                // Add MD03 upgrade plan
                url += `items[][id]=${variant?.md_03_upgrade_variant_id}%26items[][quantity]=${1}%26items[][selling_plan]=${variant?.md_03_upgrade_selling_plan_id}%26`
                // Add Remaining quantity of that product
                url += `items[][id]=${variant[variant_id_key]}%26items[][quantity]=${variant.quantity - 1}%26items[][selling_plan]=${variant[selling_plan_key]}%26`
            } else {
                if (variant[selling_plan_key]) {
                    url += `items[][id]=${variant[variant_id_key]}%26items[][quantity]=${variant.quantity}%26items[][selling_plan]=${variant[selling_plan_key]}%26`
                } else {
                    url += `items[][id]=${variant[variant_id_key]}%26items[][quantity]=${variant.quantity}%26`
                }
            }
        })
        return url
    }

    function createCartLink(products) {
        const baseUrl = `https://${process.env.REACT_APP_REPORT_SHOPIFY_DOMAIN}/cart/clear?return_to=/cart/add?`;
        let linkParts = [];

        return `${baseUrl}${getVariantUrl(products)}`;
    }

    const handleAddToCart = () => {
        if (products?.length > 0) {
            const cartLink = createCartLink(products);
            window.location.href = cartLink;
        }
    }

    const handlePopoverClick = () => {
        if (products?.length > 0) {
            setOpenCart(!openCart)
        }
    }

    function calculateTotalPrice(inputProducts) {
        const priceKey = REPORT_PLAN_DETAILS[reportPlan]?.original_price_key
        let totalPrice = 0;

        for (let i = 0; i < inputProducts.length; i++) {
            const product = inputProducts[i];
            if (product?.product_id === md03Product?.product_id) {
                totalPrice += product[priceKey] * (product.quantity - 1);
            } else {
                totalPrice += product[priceKey] * product.quantity;
            }
        }

        if (md03Product && reportPlan === REPORT_PLANS.MD03) {
            totalPrice += Number(md03Product?.price)
        }
        return totalPrice; // Return the total price
    }
    useEffect(() => {
        const calculatedPrice = calculateTotalPrice(products)
        setTotal(calculatedPrice)
    }, [products])

    const label = isMobile ? "bottom" : "top"

    return (
        <>
            <header id="PHCartheader" className={style.headerContainer + " " + style.darkHeader} style={{ [label]: showHeader ? '0' : '-80px' }}>
                <Row style={{ height: 'inherit' }}>
                    <Col xs={0} sm={4} md={4} xl={6} className={`${style.headerTabContainer}`}>
                        <div className={style.logoSection}>
                            <img src={PH_logo_light_tm} alt="Parallel" />
                        </div>
                    </Col>
                    <Col xs={24} sm={14} md={14} xl={18} className={style.buttonSection}>
                        <Space className={style.space}>
                            {isAdmin && (
                                <div className={style.showInBigScreen} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px', flexDirection: 'row' }}>
                                    <img src={search_icon_light} className={style.logoutIcon} alt='' />
                                    <OtpInput
                                        value={codeHeader}
                                        onChange={(input) => handleInputChange(input, true)}
                                        numInputs={7}
                                        separator={<span style={{ width: "8px" }}></span>}
                                        isInputNum={false}
                                        className={style.otpInput}
                                        focusStyle={{
                                            border: "1px solid #CFD3DB",
                                            outline: "none"
                                        }}
                                    />
                                    <Button type="secondary" className={style.continueButton}
                                        disabled={disableContinueHeader}
                                        onClick={() => reportSearchClickHandler(codeHeader)}>SEARCH</Button>
                                </div>
                            )}
                            <Popover
                                content={<CartList
                                    products={products}
                                    includedProducts={includedProducts}
                                    total={total}
                                    setTotal={setTotal}
                                    reportPlan={reportPlan}
                                    md03Product={md03Product}
                                />}
                                trigger="click"
                                arrow={false}
                                visible={openCart}
                                onVisibleChange={setOpenCart}
                                className={style.cartPopover}
                                onClick={handlePopoverClick}
                                overlayStyle={{ paddingRight: isMobile ? '0px' : '50px', width: isMobile ? '100%' : 'auto' }}
                            >
                                <Button type='primary' className={style.productButton}>
                                    {products?.length === 0 ? 'No Products Added Yet' : `${products.length} ${products.length > 1 ? "Products" : "Product"} added |  $${formatFloat(total)}/${PER_MONTH_LABEL}`}
                                    {openCart ? <ChevronUp /> : <ChevronDown />}
                                </Button>
                            </Popover>
                            <PhButton
                                type="secondary"
                                text={"ADD TO CART"}
                                className={style.AddButton}
                                arrow={false}
                                onClick={handleAddToCart}
                                disabled={products?.length === 0}
                            >
                            </PhButton>
                        </Space>
                    </Col>

                </Row>
            </header>
            <MD03UpgradeModal
                openModal={planModal}
                setOpenModal={updatePlanModal}
                title={REPORT_PLAN_MODAL_DETAILS[planModalType]?.title}
                subTitle={REPORT_PLAN_MODAL_DETAILS[planModalType]?.subTitle}
                cancelText={REPORT_PLAN_MODAL_DETAILS[planModalType]?.cancelText}
                successText={REPORT_PLAN_MODAL_DETAILS[planModalType]?.successText}
                onCancel={downgradeMD03Plan}
                onSuccess={() => updatePlanModal(false)}
            />
        </>
    );
};

export default PHCartHeader;

const ChevronDown = () => {
    return (
        <svg width="32" height="17" viewBox="0 0 32 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.625 3.26562C0.921875 2.63281 0.921875 1.50781 1.55469 0.875C2.1875 0.171875 3.3125 0.171875 3.94531 0.804687L16.3203 12.6172L28.625 0.804688C29.2578 0.171875 30.3828 0.171875 31.0156 0.875C31.6484 1.50781 31.6484 2.63281 30.9453 3.26562L17.4453 16.2031C17.0937 16.4844 16.6719 16.625 16.3203 16.625C15.8984 16.625 15.4766 16.4844 15.125 16.2031L1.625 3.26562Z" fill="#FFFFFF" />
        </svg>
    )
}

const ChevronUp = () => {
    return (
        <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.918 6.69922C16.4805 7.22656 16.5156 8.10547 15.9531 8.66797C15.4258 9.23047 14.5469 9.26562 13.9844 8.70312L8.5 3.46484L2.98047 8.70312C2.41797 9.26562 1.53906 9.23047 1.01172 8.66797C0.449219 8.10547 0.484375 7.22656 1.04688 6.69922L7.51562 0.511719C7.79688 0.230469 8.14844 0.125 8.5 0.125C8.81641 0.125 9.16797 0.230469 9.44922 0.511719L15.918 6.69922Z"
                fill="#FFFFFF" />
        </svg>

    )
}



const CartList = ({ products, includedProducts, reportPlan, md03Product, total, setTotal }) => {

    const [showAddOns, setShowAddOns] = useState(false)

    useEffect(() => {
        if (products?.length === 1 && md03Product) {
            setShowAddOns(false)
        } else if (products?.length > 0) {
            setShowAddOns(true)
        } else {
            setShowAddOns(false)
        }
    }, [products])
    return (
        <div className={style.cartContainer}>
            <div className={style.cartTitleContainer}>
                {REPORT_PLAN_DETAILS[reportPlan]?.cart_title}
            </div>
            <div className={style.subCartContainer}>
            {includedProducts && includedProducts.length > 0 && (
                <SubCartSection
                    title={'INCLUDED WITH MD-03 PHAGE PROTOCOL'}
                    products={includedProducts}
                    showDiscount={true}
                    reportPlan={reportPlan}
                />
            )}

            {md03Product && (
                <SubCartSection
                    title={'MD-03 PHAGE PROTOCOL UPGRADE'}
                    products={[md03Product]}
                    showDiscount={false}
                    divider={includedProducts?.length > 0 ? true : false}
                    reportPlan={reportPlan}

                />
            )}
            {showAddOns && (
                <SubCartSection
                    title={REPORT_PLAN_DETAILS[reportPlan]?.cart_addon_title}
                    products={products}
                    md03Product={md03Product}
                    showDiscount={REPORT_PLAN_DETAILS[reportPlan]?.show_product_discount}
                    divider={includedProducts?.length > 0 || md03Product ? true : false}
                    reportPlan={reportPlan}
                />
                )}
                <div className={style.subTotal}>
                </div>
            </div>

            <div className={style.total}>
                <Text className={style.itemTitle}>
                    Total
                </Text>
                <Text className={style.itemPrice}>
                    ${formatFloat(total)}/{PER_MONTH_LABEL}
                </Text>
            </div>
        </div>
    )
}

const SubCartSection = ({ title, products, showDiscount, md03Product, reportPlan, divider = false }) => {

    return (
        <div className={`${style.subCartSection} ${divider ? style.divider : ''}`}>
            <div className={style.innerContainer}>
            <div className={style.subCartTitle}> {title}</div>

            {products.map(item => (
                ((item?.product_id === md03Product?.product_id && item?.quantity > 1) || item?.product_id !== md03Product?.product_id) ? (
                    <SubCartProduct item={item} reportPlan={reportPlan} md03Product={md03Product} showDiscount={showDiscount} key={item?.product_id} />
                ) : <></>
            ))}
            </div>
        </div>
    )
}

const SubCartProduct = ({ item, reportPlan, md03Product, showDiscount }) => {
    const discountPrice = item && item[REPORT_PLAN_DETAILS[reportPlan]?.original_price_key] !== undefined ? item[REPORT_PLAN_DETAILS[reportPlan]?.original_price_key] : null;
    const originalPrice = item && item[REPORT_PLAN_DETAILS[reportPlan]?.discount_price_key] !== undefined ? item[REPORT_PLAN_DETAILS[reportPlan]?.discount_price_key] : null;

    return (
        <div className={style.cartItem}>
            <Text className={style.itemTitle}>
                {item.name} {`(${(item?.product_id === md03Product?.product_id ? item?.quantity - 1 : item?.quantity ?? 1)})`}
            </Text>
            {showDiscount ? (
                <div className="center-flex">
                    <span className={style.itemPrice}>{`$${formatFloat(discountPrice) * (item?.product_id === md03Product?.product_id ? item?.quantity - 1 : item?.quantity ?? 1)}/MO`}</span>
                    <span className={style.discountPrice}>{`$${formatFloat(originalPrice) * (item?.product_id === md03Product?.product_id ? item?.quantity - 1 : item?.quantity ?? 1)}`}</span>
                </div>
            ) : (
                <Text className={style.itemPrice}>
                        {`$${formatFloat(originalPrice) * (item?.product_id === md03Product?.product_id ? item?.quantity - 1 : item?.quantity ?? 1)}/${PER_MONTH_LABEL}`}
                </Text>
            )}
        </div>
    )
}